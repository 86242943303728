::selection {
  /* @apply bg-selection !important; */
  background-color: #ffffff33;
}

::-moz-selection {
  /* @apply bg-selection !important; */
  background-color: #ffffff33;
}

.app ::selection {
  @apply bg-selection !important;
}

.app ::-moz-selection {
  @apply bg-selection !important;
}

.prism-code ::selection {
  @apply bg-selectionCode !important;
}

.prism-code ::-moz-selection {
  @apply bg-selectionCode !important;
}
