.fit {
  min-height: calc(100vh - 88px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}

input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.form-checkbox:focus,
.form-radio:focus,
.form-checkbox,
.form-radio,
.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  border-color: red;
  color: green;
  outline: 4px solid orange;
}

.app strong,
.dashboard strong {
  font-weight: 600;
}

.app table,
.dashboard table {
  @apply w-full;
}

.app td,
.app th,
.dashboard td,
.dashboard th {
  @apply pr-4;
}

.app td:last-child,
.app th:last-child,
.dashboard td:last-child,
.dashboard th:last-child {
  @apply pr-0;
}

.dashboard {
  @apply max-w-full !important;
}

.dashboard tr {
  border: 0 !important;
  margin: -10px;
}

.dashboard td,
.dashboard th {
  @apply px-2 !important;
}

.dashboard .zebra tr:nth-child(even) {
  @apply bg-neutral-50;
}

.dashboard thead {
  @apply border-neutral-200 !important;
}

.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

.hiddenScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.subtleUnderline {
  text-decoration: underline dotted #94a3b8;
}

.subtleUnderlineWhite {
  text-decoration: underline dotted #ffffff80;
}

.subtleUnderlineError {
  @apply underline decoration-rose-500 decoration-dotted;
}

.subtleLink {
  @apply cursor-default select-none text-neutral-900 underline decoration-neutral-400 decoration-dotted transition hover:opacity-70;
}

.animate-caret {
  animation: caret 1s cubic-bezier(0.14, 0, 0.16, 1) forwards infinite;
}

.filter-no-blur {
  filter: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=‘0’);
}

.drag {
  -webkit-app-region: drag;
}

.nodrag {
  -webkit-app-region: no-drag;
}

tbody.basetable:before {
  content: '@';
  display: block;
  line-height: 2px;
  text-indent: -99999px;
}

.frac-line {
  border-bottom-color: black;
}

.editorErrorLine {
  color: #ef4444 !important;
}

.editorErrorLineGlyph {
  margin-top: 8px;
  margin-left: 11px;
}

/* .codicon-folding-expanded:before {
  display: block;
  content: ' ' !important;
  background-image: url('../../public/static/icons/svg/chevron-down.svg');
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
}

.codicon-folding-collapsed:before {
  display: block;
  content: ' ' !important;
  background-image: url('../../public/static/icons/svg/chevron-right.svg');
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
} */

.overflow-hidden-safari-fix {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.motif-inline-code::before {
  content: '`';
}

.motif-inline-code::after {
  content: '`';
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.infoText {
  @apply text-xs text-neutral-600;
}

.dialogTitle {
  @apply select-none text-lg font-semibold leading-6 text-neutral-900;
}

.dialogTitleLarge {
  @apply select-none text-2xl font-bold leading-6 text-neutral-900;
}

.dialogDescription {
  @apply mt-2 select-none text-sm text-neutral-700;
}

.pageTitle {
  @apply mb-2 select-none font-semibold;
}

.pageDescription {
  @apply select-none text-sm leading-relaxed text-neutral-500;
}

.toast {
  @apply pointer-events-none flex select-none items-center rounded-md bg-black px-3 py-1 text-center text-sm text-white !important;
}

.Resizer {
  background-color: theme('colors.gray.200');
  z-index: 1;
  background-clip: padding-box;
}

.Resizer-transparent {
  background-color: #00000000;
  width: 0 !important;
  z-index: 1;
  background-clip: padding-box;
}

.dark .Resizer {
  background-color: theme('colors.gray.800');
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  cursor: ns-resize;
  width: 100%;
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  cursor: ew-resize;
}

.Resizer.Resizer-collapsed {
  width: 0;
  height: 0;
}

.Resizer.disabled {
  pointer-events: none;
}

input[type='checkbox'] {
  @apply h-[14px] w-[14px] rounded-[3px] border border-neutral-300 text-sky-500 transition focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 focus:ring-offset-0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
}

input[type='checkbox']:checked {
  @apply border-sky-500 bg-sky-500 !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

input[type='radio'] {
  @apply h-[14px] w-[14px] rounded-full border border-neutral-300 text-sky-500 transition focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 focus:ring-offset-0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
}

input[type='radio']:checked {
  @apply border-sky-500 bg-sky-500 !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.animate-caret {
  animation: caret 1s cubic-bezier(0.14, 0, 0.16, 1) forwards infinite;
}

.chat-item-container {
  animation-name: animate-height;
  animation-duration: 0.5s;
  @apply ease-in-out;
}

.chat-item {
  animation-name: slideup;
  animation-duration: 0.5s;
  @apply ease-in-out;
}

.chat-item-loading {
  animation-name: slideup;
  animation-duration: 0.5s;
  animation-delay: 1s;
  @apply ease-in-out;
}

@keyframes animate-height {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}

@keyframes slideup {
  from {
    margin-bottom: -20px;
    opacity: 0;
  }
  5% {
    margin-bottom: -20px;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}

.home-dots {
  background-image: radial-gradient(#ffffff25 6%, transparent 6%);
  background-color: transparent;
  background-position: 0 0, 1px 1px;
  background-size: 12px 12px;
}

.home-gradient-text {
  /* This fixes right clipped gradient */
  padding: 0 4px;
  background-color: #f59e0b;
  background-image: linear-gradient(45deg, #bae6fd, #d9f99d);
  /* background-image: linear-gradient(45deg, #a3e635, #f59e0b); */
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.fade-in-slide-up {
  animation-name: fade-in-slide-up;
  animation-duration: 500ms;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.25, 0.4, 0.55, 1.4);
}

@keyframes fade-in-slide-up {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-slide-up-long {
  animation-name: fade-in-slide-up-long;
  animation-duration: 1000ms;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.25, 0.4, 0.55, 1.4);
}

@keyframes fade-in-slide-up-long {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-slide-down {
  animation-name: fade-in-slide-down;
  animation-duration: 500ms;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.25, 0.4, 0.55, 1.4);
}

@keyframes fade-in-slide-down {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 500ms;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.25, 0.4, 0.55, 1.4);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-dialog-slide-in {
  transition-timing-function: cubic-bezier(0.25, 0.4, 0.55, 1.4);
  animation-fill-mode: both;
}

.animate-dialog-slide-in[data-state='open'] {
  animation-duration: 300ms;
  animation-name: dialog-slide-in;
}

.animate-dialog-slide-in[data-state='closed'] {
  animation-duration: 400ms;
  animation-name: dialog-slide-in-reverse;
}

.dialog-content {
  @apply bg-neutral-1000 fixed top-[50%] left-[50%] z-40 translate-x-[-50%] translate-y-[-50%] overflow-y-auto rounded-lg border border-neutral-900 antialiased shadow-2xl;
}

@keyframes dialog-slide-in {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes dialog-slide-in-reverse {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
}

.animate-overlay {
  transition-timing-function: cubic-bezier(0.25, 0.4, 0.55, 1.4);
  animation-fill-mode: both;
}

.animate-overlay[data-state='open'] {
  animation-duration: 300ms;
  animation-name: opacity;
}

.animate-overlay[data-state='closed'] {
  animation-duration: 400ms;
  animation-name: opacity-reverse;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-reverse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.home-form input:autofill,
.home-form input:-webkit-autofill {
  @apply border-sky-500 !important;
}

.home-form label {
  @apply mt-2 text-xs font-medium text-neutral-300;
}

.home-form input,
.home-form textarea {
  @apply rounded-md border bg-neutral-900 px-2 py-2 text-sm text-neutral-300 outline-none ring-sky-500 transition duration-300 placeholder:text-neutral-500 focus:ring-2;
}

.home-form input:not([data-error]),
.home-form textarea:not([data-error]) {
  @apply border-neutral-800;
}

.home-form input[data-error],
.home-form textarea[data-error] {
  @apply border-rose-900 focus:border-neutral-800;
}

.home-form input[type='radio'] {
  @apply h-[14px] w-[14px] rounded-full border border-neutral-800 text-sky-500 transition focus:border-sky-500 focus:bg-sky-500 focus:ring focus:ring-sky-200 focus:ring-opacity-50 focus:ring-offset-0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.home-icon-button {
  @apply rounded-md p-1 text-neutral-500 outline-none ring-sky-500 transition hover:bg-white/5 hover:text-white focus:text-white focus:ring;
}

.home-ghost-button {
  @apply whitespace-nowrap rounded-md border-0 bg-transparent px-2 py-2 text-xs text-neutral-500 outline-none ring-sky-500 transition duration-300 hover:bg-white/5 hover:text-white focus:ring sm:px-3;
}

.home-border-button {
  @apply rounded-md border border-neutral-800 bg-neutral-900 px-2 py-2 text-sm font-medium text-neutral-300 outline-none ring-sky-500 transition duration-300 hover:border-neutral-700 hover:bg-neutral-800 hover:text-white focus:ring sm:px-3;
}

.home-a {
  @apply rounded-sm border-0 font-medium underline decoration-neutral-700 outline-none ring-sky-500 transition duration-300 focus:ring;
  text-underline-offset: 3px;
}
