.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents {
  display: block;
}

.monaco-editor .inline-folded:after {
  display: inline;
  content: ' ' !important;
}

.monaco-editor .folded-background {
  background-color: transparent !important;
}

.suggest-widget .slider {
  display: none !important;
  opacity: 0 !important;
}

.suggest-widget .message,
.suggest-widget .tree {
  overflow: hidden;
  background-color: #fff !important;
  border-radius: 0.25rem !important;
  border-color: #e4e4e7 !important;
  border-width: 1px !important;
}

.suggest-widget .message {
  color: #a1a1aa;
  font-size: 1rem;
}

.suggest-widget .suggest-status-bar {
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
}

.suggest-widget .details {
  display: none !important;
  opacity: 0 !important;
}

.suggest-widget .monaco-list-row {
  padding: 0 3px;
}

.monaco-list-row.focused {
  background-color: #f5f5f5 !important;
}
.monaco-list-row.focused:hover {
  background-color: #f5f5f5 !important;
}
.monaco-list-row.selected {
  background-color: #06b6d4 !important;
}
.monaco-list-row.selected:hover {
  background-color: #06b6d4 !important;
}
.monaco-list-row.selected {
  color: #ffffff;
}
.monaco-drag-image,
.monaco-list-row.selected.focused {
  background-color: #22d3ee !important;
}
.monaco-drag-image,
.monaco-list-row.selected.focused {
  color: #ffffff;
}
.monaco-list-row.focused {
  background-color: #f5f5f5 !important;
}
.monaco-list-row.focused:hover {
  background-color: #f5f5f5 !important;
}
.monaco-list-row.selected {
  background-color: #f5f5f5 !important;
}
.monaco-list-row.selected:hover {
  background-color: #f5f5f5 !important;
}
.monaco-list.list_id_1:not(.drop-target)
  .monaco-list-row:hover:not(.selected):not(.focused) {
  background-color: #f5f5f5 !important;
}
.monaco-list.list_id_1.drop-target,
.monaco-list.list_id_1 .monaco-list-rows.drop-target,
.monaco-list.list_id_1 .monaco-list-row.drop-target {
  background-color: #f5f5f5 !important;
  color: inherit !important;
}
.monaco-list-type-filter {
  background-color: #efc1ad;
}
.monaco-list-type-filter {
  border: 1px solid rgba(0, 0, 0, 0);
}
.monaco-list-type-filter.no-matches {
  border: 1px solid #be1100;
}
.monaco-list-type-filter {
  box-shadow: 1px 1px 1px #a8a8a8;
}

.monaco-highlighted-label .highlight {
  color: #06b6d4 !important;
}

.monaco-editor.vs .line-numbers {
  @apply text-neutral-300 opacity-0 transition;
}

.monaco-editor .line-numbers.active-line-number {
  @apply text-neutral-700 opacity-0 transition !important;
}

.showLineNumbers .monaco-editor.vs .line-numbers {
  @apply opacity-100 transition duration-500;
}

.showLineNumbers .monaco-editor .line-numbers.active-line-number {
  @apply opacity-100 transition duration-500 !important;
}

/* fix multiline text selection */
/* .monaco-editor .lines-content {
  margin-left: 50px;
  padding-right: 50px;
} */

/* .monaco-editor .lines-content {
  padding-left: 5px;
} */

/* .monaco-editor .margin {
  width: 50px;
}

.monaco-editor .margin-view-overlays {
  width: 50px;
}

.monaco-editor .margin-view-zones {
  width: 50px;
}

.monaco-editor .glyph-margin {
  width: 50px;
}

.monaco-editor .view-line {
  padding-left: 50px;
}

.monaco-editor .cursor {
  margin-left: 50px;
}

.monaco-editor .selectionHighlight {
  margin-left: 50px;
}

.monaco-editor .bracket-match {
  margin-left: 50px;
}

.monaco-editor .cslr.selected-text {
  margin-left: 50px;
}

.monaco-editor .bottom-left-radius,
.monaco-editor .top-left-radius {
  margin-left: 50px;
}
*/

.monaco-editor .find-widget {
  @apply top-1 rounded-md bg-neutral-100 font-sans !important;
  box-shadow: 0 0 0 1px #e5e5e5 !important;
}

.monaco-editor .find-widget .monaco-sash {
  @apply bg-neutral-100 !important;
}

.monaco-findInput {
  @apply overflow-hidden rounded-sm bg-white transition  !important;
  caret-color: #15e3ff;
}

.monaco-inputbox.synthetic-focus {
  @apply rounded-md shadow-lg transition !important;
  outline-color: #d4d4d4 !important;
  outline-width: 1px;
  outline-style: solid;
  outline-offset: 0px;
}

.monaco-mouse-cursor-text {
  @apply rounded-full !important;
}

.cursors-layer,
.cursors-layer > *,
.cursor-line-style > *,
.cursor-solid > * {
  color: #15e3ff !important;
  background-color: #15e3ff !important;
  border-color: #15e3ff !important;
}

.ySelection-0 {
  @apply bg-orange-100;
}
.ySelection-1 {
  @apply bg-green-100;
}
.ySelection-2 {
  @apply bg-sky-100;
}
.ySelection-3 {
  @apply bg-violet-100;
}
.ySelection-4 {
  @apply bg-rose-100;
}
.ySelection-5 {
  @apply bg-amber-100;
}
.ySelection-6 {
  @apply bg-lime-100;
}
.ySelection-7 {
  @apply bg-fuchsia-100;
}

.yBackground-0 {
  @apply bg-orange-400;
}
.yBackground-1 {
  @apply bg-green-400;
}
.yBackground-2 {
  @apply bg-sky-400;
}
.yBackground-3 {
  @apply bg-violet-400;
}
.yBackground-4 {
  @apply bg-rose-400;
}
.yBackground-5 {
  @apply bg-amber-400;
}
.yBackground-6 {
  @apply bg-lime-400;
}
.yBackground-7 {
  @apply bg-fuchsia-400;
}

.yBorder-0 {
  @apply border-orange-400;
}
.yBorder-1 {
  @apply border-green-400;
}
.yBorder-2 {
  @apply border-sky-400;
}
.yBorder-3 {
  @apply border-violet-400;
}
.yBorder-4 {
  @apply border-rose-400;
}
.yBorder-5 {
  @apply border-amber-400;
}
.yBorder-6 {
  @apply border-lime-400;
}
.yBorder-7 {
  @apply border-fuchsia-400;
}

.ySelectionHead {
  @apply absolute box-border h-full border-l-2 border-t border-b;
}

.editor-mono {
  @apply antialiased !important;
  /* Other candidate: 'Noto Sans Mono' */
  font-family: 'IBM Plex Mono', Menlo, ui-monospace, SFMono-Regular, Monaco,
    Consolas, 'Liberation Mono', 'Courier New', monospace;
}

/* Suggestions widget */

.suggest-widget {
  @apply border-none bg-transparent !important;
  max-width: 16rem !important;
}
.suggest-widget .message,
.suggest-widget .tree {
  @apply rounded border border-neutral-200 bg-white !important;
}

.monaco-list.list_id_1 .monaco-list-row.focused {
  @apply bg-neutral-100 !important;
}

.monaco-list-row.focused {
  @apply bg-white !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused {
  @apply text-slate-900 !important;
}

.monaco-highlighted-label .highlight {
  @apply text-cyan-500 !important;
}

.suggest-widget .readMore {
  @apply hidden !important;
}

.suggest-widget .right .details-label {
  @apply text-xs text-neutral-400 !important;
  padding-top: 5px !important;
}

.suggest-widget .right .details-label {
  @apply opacity-100 !important;
}

.monaco-editor .monaco-hover {
  @apply rounded border border-neutral-200 bg-neutral-100 !important;
}

.vs .monaco-scrollable-element > .scrollbar.vertical > .slider {
  @apply rounded-full bg-neutral-500 bg-opacity-75 !important;
  -webkit-box-shadow: inset 0px 0px 0px 1px #fafafa;
  -moz-box-shadow: inset 0px 0px 0px 1px #fafafa;
  box-shadow: inset 0px 0px 0px 1px #fafafa;
}

.vs .monaco-scrollable-element > .scrollbar.vertical > .slider {
  @apply opacity-0 transition duration-500 !important;
}

.scrollbarVisible
  .vs
  .monaco-scrollable-element
  > .scrollbar.vertical
  > .slider {
  @apply opacity-100 transition duration-200 !important;
}

.iPadShowKeyboard {
  @apply -top-60 hidden opacity-0 !important;
}

/* Diff editor shadow */
.monaco-diff-editor.side-by-side .editor.modified {
  box-shadow: none !important;
  @apply border-l border-neutral-200;
}

/* Hide the lightbulb in diff editor for a deleted line. */
.monaco-diff-editor .inline-deleted-margin-view-zone {
  @apply pointer-events-none !important;
}

.monaco-diff-editor .inline-deleted-margin-view-zone .codicon {
  @apply hidden !important;
}

.monaco-diff-editor .monaco-mouse-cursor-text {
  @apply rounded-none !important;
}

/* Read-only popup */
.monaco-editor-overlaymessage .message {
  @apply translate-y-[5px] transform rounded-md border-transparent bg-sky-100 px-2 py-1 text-sm text-sky-500 !important;
}

.monaco-editor-overlaymessage .anchor.below {
  @apply border-transparent !important;
}
