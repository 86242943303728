.appearWithLongDelay {
  animation: fadeIn linear 0ms forwards;
  -webkit-animation: fadeIn linear 0ms forwards;
  -moz-animation: fadeIn linear 0ms forwards;
  -o-animation: fadeIn linear 0ms forwards;
  -ms-animation: fadeIn linear 0ms forwards;
  animation-delay: 100ms;
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  -o-animation-delay: 100ms;
  -ms-animation-delay: 100ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes toast-enter {
  0% {
    opacity: 0;
    transform: translateY(20%);
    transition: all;
  }
  ,
  100% {
    opacity: 1;
    transform: translateY(0);
    transition: all;
  }
}

@keyframes toast-exit {
  0% {
    opacity: 1;
    transform: translateY(0);
    transition: all;
  }
  ,
  100% {
    opacity: 0;
    transform: translateY(20%);
    transition: all;
  }
}

.bounce {
  transition: 0.3s cubic-bezier(0.4, 1.58, 0.56, 0.93);
}
