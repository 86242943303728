pre {
  @apply overflow-x-auto rounded-md bg-slate-900 p-6 text-sm text-white antialiased !important;
  line-height: 1.7142857 !important;
}

.code-small pre {
  @apply p-3 text-xs antialiased !important;
  line-height: 1.7142857 !important;
}

.token.plain {
  @apply text-white !important;
}

.token.tag,
.token.class-name,
.token.selector,
.token.selector .class,
.token.function {
  @apply text-fuchsia-400 !important;
}

.token.attr-name,
.token.keyword,
.token.rule,
.token.operator,
.token.pseudo-class,
.token.important {
  @apply text-cyan-400 !important;
}

.token.attr-value,
.token.class,
.token.string,
.token.number,
.token.unit,
.token.color {
  @apply text-lime-300 !important;
}

.token.punctuation,
.token.module,
.token.property {
  @apply text-sky-200 !important;
}

.token.atapply .token:not(.rule):not(.important) {
  color: inherit;
}

.language-shell .token:not(.comment) {
  color: inherit;
}

.language-css .token.function {
  color: inherit;
}

.token.comment {
  @apply text-neutral-400 !important;
}

.token.deleted:not(.prefix) {
  @apply relative -mx-4 block px-4 !important;
}

.token.deleted:not(.prefix)::after {
  content: '';
  @apply pointer-events-none absolute inset-0 block bg-rose-400 bg-opacity-25 !important;
}

.token.deleted.prefix {
  @apply select-none text-neutral-400 !important;
}

.token.inserted:not(.prefix) {
  @apply -mx-4 block bg-emerald-700 bg-opacity-50 px-4 !important;
}

.token.inserted.prefix {
  @apply select-none text-emerald-200 text-opacity-75 !important;
}
