@import 'base.css';
@import 'chrome-bug.css';

/*! purgecss start ignore */

@import 'tailwindcss/components';
@import 'utilities.css';
@import 'components.css';
@import 'animations.css';
@import 'monaco.css';
@import 'selectors.css';
@import 'prism.css';
@import 'tippy.css';

.app,
.dashboard {
  font-feature-settings: 'kern';
  text-rendering: optimizeLegibility;
}

html,
body,
#__next {
  height: 100%;
}

body.cursor-ew-resize,
body.cursor-ew-resize * {
  cursor: ew-resize !important;
}

body.cursor-ns-resize,
body.cursor-ns-resize * {
  cursor: ns-resize !important;
}

body.cursor-nesw-resize,
body.cursor-nesw-resize * {
  cursor: nesw-resize !important;
}

body.cursor-nwse-resize,
body.cursor-nwse-resize * {
  cursor: nwse-resize !important;
}

/* textarea,
textarea:focus {
  @apply border-0 border-red-400 shadow-none outline-none drop-shadow-none !important;
} */

/* purgecss start ignore */
/* purgecss end ignore */
